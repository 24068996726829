import React, {useState} from 'react';
import { Navbar } from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import './Referral.css'
import referOne from '../../assets/referral2.webp';
import referT from '../../assets/referral3.png'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress'; 
import axios from 'axios';
import validator from "validator";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Referral = () => {

  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    mobileNumber: '',
    status: '',
    company: '',
    
    companyName: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactNumber: '',
    message: ''
    });
    
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleOpenError = () => {
      setOpenError(true);
    };
  
    const handleCloseError = () => {
      setOpenError(false);
    };
    const success = () => {
      setSuccessMessageVisible(true);
    }

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      if (name === 'workEmail') {
        validateEmail(value);
      }
    };
  
    const validateEmail = (email) => {
      if (validator.isEmail(email)) {
        setMessage('Thank you');
        console.log('thank you')
      } else {
        setMessage('Please, enter a valid Email!');
        console.log('valide email')
      }
    };

    // Function to get the employment status
    const getStatus = (status) => {
      const employmentStatus = {
        'Full Time': '01',
        'Part Time': '02',
        'Student': '03',
        'Unemployed': '04',
        'Other': '05'
      };
      return employmentStatus[status] || status;
    };

    // Function to generate token
    const generateToken = async () => {
      try {
        const response = await axios.post('https://api-j8jt.onrender.com/post-data', {
          url: 'https://acumatica.futurekenya.com/AcumaticaERP/identity/connect/token',
          data: {
            grant_type: 'password',
            username: 'development',
            password: 'D3v3l0p@Fkl',
            scope: 'api',
            client_id: '87D73C8E-BFC0-D9EF-5E2A-DF9341A0D3D0@Future Kenya',
            client_secret: 'hanBCN6rDihEEm47B0ZsFg'
          }
        });
    
        const accessToken = response.data.access_token;
        console.log(accessToken);
        return accessToken;
      } catch (error) {
        console.error('Error generating token:', error);
        return null;
      }
    };

    const sendDataWithToken = async () => {
      try {
        const token = await generateToken();
        console.log("token", token)
        if (!token) return;

        // Update the dataToSend object to use values from the formData state
        const dataToSend = {
        FirstName: { value: formData.contactFirstName},
        LastName: { value: formData.contactLastName },
        Email: { value: formData.contactEmail },
        Phone2Type: { value: 'cell' },
        Phone2: { value: formData.contactNumber },
        CompanyName: { value: formData.companyName },
        LeadClass: {
          value: "WEBREFER"
        },
        Attributes: [
          {
            AttributeDescription: { value: "Referral First Name" },
            AttributeID: { value: "FNAME" },
            Required: {},
            Value: { value: formData.contactFirstName },
            ValueDescription: { value: formData.firstName }
          },
          {
            AttributeDescription: { value: "Referral Last Name" },
            AttributeID: { value: "LNAME" },
            Required: {},
            Value: { value: formData.contactLastName },
            ValueDescription: { value: formData.lastName }
          },
          {
            AttributeDescription: { value: "Referral Work Email" },
            AttributeID: { value: "WEMAIL" },
            Required: {},
            Value: { value: formData.contactEmail },
            ValueDescription: { value: formData.workEmail }
          },
          {
            AttributeDescription: { value: "Referral Mobile Number" },
            AttributeID: { value: "MOBILENUMB" },
            Required: {},
            Value: { value: formData.contactNumber },
            ValueDescription: { value: formData.mobileNumber }
          },

          {
            AttributeDescription: { value: "Referral Employment Status" },
            AttributeID: { value: "EMPSTATUS" },
            Required: {},
            Value: { value: getStatus(formData.status) },
            ValueDescription: { value: formData.status }
          },

          {
            AttributeDescription: { value: "Referral Company Name" },
            AttributeID: { value: "COMPANYNAM" },
            Required: {},
            Value: { value: formData.companyName },
            ValueDescription: { value: formData.company }
          }
        ],
        Description: { value: formData.message }
        };
  
        const response = await axios.put('https://api-j8jt.onrender.com/create-lead', {
          url: 'https://acumatica.futurekenya.com/AcumaticaERP/entity/Default/22.200.001/Lead?$select=FirstName,LastName,Email,Phone2Type,CompanyName,Description,Phone2,  Attributes/AttributeID, Attributes/Value&$expand=Attributes',
          data: dataToSend,
          token: token
        });
    
        console.log('Response:', response.data);
      } catch (error) {
        console.error('Error sending data:', error);
      }
    };

    const handleFormSubmit = async (e) => {

      setIsLoading(true)
      e.preventDefault();
      setButtonDisabled(true);
    
      try {
        await sendDataWithToken();
        setSuccessMessageVisible(true);
        setFormData({
          firstName: '',
          lastName: '',
          workEmail: '',
          mobileNumber: '',
          status: '',
          company: '',

          companyName: '',
          contactFirstName: '',
          contactLastName: '',
          contactEmail: '',
          contactNumber: '',
          message: ''
        });
        setOpen(true); // Show success alert dialog
      } catch (error) {
        setOpenError(true);
      } finally {
        setIsLoading(false);
      }

    }

  return (
    <div>
      <Navbar />
      <div className="referralContainer">
        <div className='flexContainer'>
          <div className='refer'>
            <img src={referOne} alt="future kenya referrals"/>
            <img src={referT} alt="future kenya referrals"/>
            <p>
              Refer your friends and colleagues to purchase Acumatica – The Cloud ERP! You will help their businesses succeed with the industry leading Cloud ERP solution and you will earn up to <span style={{color: 'red'}}>KSH 125,000 !!</span>
            </p>
            <div className='form'>
              <h3 style={{paddingLeft: '1.5rem', fontSize: '1.3rem', marginTop: '0'}}>Register Now</h3>
              <div className='referForm'>
                <form onSubmit={handleFormSubmit}>
                    <div className='firstRow'>
                        <input
                        type='text'
                        name='firstName'
                        value={formData.firstName}
                        placeholder='First Name'
                        onChange={handleInputChange}
                        required
                        />
                        <input
                        type='text'
                        name='lastName'
                        value={formData.lastName}
                        placeholder='Last Name'
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div className='firstRow'>
                        <input
                        type='text'
                        name='workEmail'
                        value={formData.workEmail}
                        placeholder='Work Email'
                        onChange={handleInputChange}
                        required
                        />
                        <input 
                        type='text'
                        name='mobileNumber'
                        value={formData.mobileNumber}
                        placeholder='Mobile Number'
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div className='firstRow'>
                        <select
                        name='status'
                        value={formData.status}
                        onChange={handleInputChange}
                        required
                        >
                            <option value="" disabled selected hidden>Employment Status</option>
                            <option value="Full time">Full time</option>
                            <option value="Part time">Part time</option>
                            <option value="Student">Student</option>
                            <option value="Unemployed">Unemployed</option>
                            <option value="Other">Other</option>
                        </select>
                        <input 
                        type='text'
                        name='company'
                        value={formData.company}
                        placeholder='Company'
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div style={{padding: 0}}>
                        <h3 style={{fontSize: '1.3rem', color: 'white', margin: '-.5rem'}}>Your Lead</h3>
                    </div>
                    <div className='firstRow'>
                        <input
                        type='text'
                        name='companyName'
                        value={formData.companyName}
                        placeholder='Company Name'
                        onChange={handleInputChange}
                        required
                        />
                        <input
                        type='text'
                        name='contactFirstName'
                        value={formData.contactFirstName}
                        placeholder='Contact First Name'
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div className='firstRow'>
                        <input
                        type='text'
                        name='contactLastName'
                        value={formData.contactLastName}
                        placeholder='Contact Last Name'
                        onChange={handleInputChange}
                        required
                        />
                        <input
                        type='text'
                        name='contactEmail'
                        value={formData.contactEmail}
                        placeholder='Contact Email'
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <div className='firstRow'>
                      <input 
                        style={{width: '40%'}}
                        type='text'
                        name='contactNumber'
                        value={formData.contactNumber}
                        placeholder='Contact Number'
                        onChange={handleInputChange}
                        required
                        />
                    </div>
                    <textarea
                        name='comments'
                        value={formData.comment}
                        placeholder="Comments"
                        onChange={handleInputChange}
                        rows={4}
                        style={{ resize: 'vertical', borderRadius: '2rem 0 2rem 0' }}
                    ></textarea>
                      <button className='btnsubmit' style={{ width: '30%', padding: '.5rem', cursor: 'pointer' }} type='submit' disabled={isLoading}>
                        {!isLoading ? 'Submit' : 'Sending data...'}
                      </button>
                      {isLoading && <CircularProgress size={24} style={{ marginLeft: '10px' }} />} {/* Render CircularProgress when isLoading is true */}
                </form>
              </div>
            </div>
          </div>
          <div className='terms'>
            <h3>
              Please read before entering
            </h3>
            <p>
              Earn between KSH 70,000 - KSH 125,000 when your referral becomes an Acumatica customer!
            </p>
            <p>
              Only Prime (Advanced) and Enterprise (Enterprise X) Editions Qualify.
            </p>
            <ul className='circleList'>
              <li>Financials – Maximum KSH 70,000</li>
              <li>Distribution - Maximum KSH 80,000</li>
              <li>Manufacturing - Maximum KSH 90,000</li>
              <li>Complimented with 2 or more add-ons CRM, Case Management, POS etc – Full Pay Out KSH 125,000</li>
            </ul>
            <p>
              Essentials and Small Business Editions will receive 30% consideration of the full payout.
            </p>
            <h3>
              Terms and Conditions
            </h3>
            <ol>
              <li>Customer must have paid for the software license for the participant to earn the referral payout.</li>
              <li>Only applicants that have registered through the form on the website are eligible for this promotion.</li>
              <li>Participants are not allowed to refer their own companies or companies they are employed at.</li>
              <li>Participants are not allowed to refer immediate family members.</li>
              <li>Future Kenya has the right to investigate breach of terms and conditions and under solid ground, terminate ones right to a referral payout.</li>
              <li>Employees of Future Kenya are not allowed to register for the program.</li>
              <li>All payments are deductible as per KRA tax laws.</li>
              <li>Future Kenya has the right to dictate the timeline of the program and the right to terminate the program with fair notice to participants.</li>
            </ol>
          </div>
        </div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Stack sx={{ width: '100%' }}>
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                We have received your enquiry and we will be in touch shortly
              </Alert>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>
        {/* Error alert dialog */}
        <Dialog
          open={openError}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseError}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="warning">An Error occurred while sending the Enquiry. Please try again.</Alert>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError}>Ok</Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  )
}

export default Referral;
